import {Component, ViewChild} from '@angular/core';
import {IonSlides, ModalController} from '@ionic/angular';
import {NavigationService} from 'src/app/services/navigation.service';

import {sleep} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-loyalty-slider-modal',
	templateUrl: './loyalty-slider-modal.component.html',
	styleUrls: ['loyalty-slider-modal.component.scss']
})
export class LoyaltySliderModalComponent {
	isAnonymous!: boolean;
	constructor(
		private modalCtrl: ModalController,
		private navService: NavigationService
	) {}

	@ViewChild(IonSlides, {static: false}) slides: IonSlides;
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: true,
		navigation: {
			nextEl: '.slide-next-btn',
			prevEl: '.slide-prev-btn'
		}
	};

	static async show(modalCtrl: ModalController, isAnonymous: boolean) {
		const modal = await modalCtrl.create({
			cssClass: 'loyalty-slider-modal',
			component: LoyaltySliderModalComponent,
			componentProps: {
				isAnonymous
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result;
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	async toRegistration() {
		this.modalCtrl.dismiss('toSignUp');
		await this.navService.signUp();
	}
	async toLogin() {
		this.modalCtrl.dismiss('toSignIn');
		await this.navService.signIn();
	}

	slideNext() {
		this.slides.slideNext();
	}
	slidePrev() {
		this.slides.slidePrev();
	}
}
