import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import Order from '../../../smoothr-web-app-core/models/Order';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {environment} from '../../../environments/environment';
import {numberToCurrency} from 'src/smoothr-web-app-core/utils/utils';
import {OrderPage} from 'src/app/pages/order/order.page';

@Component({
	selector: 'app-to-order-button',
	templateUrl: './to-order-button.component.html',
	styleUrls: ['to-order-button.component.scss']
})
export class ToOrderButtonComponent {
	numberToCurrency = numberToCurrency;
	@Input() order: Order;

	constructor(private router: Router) {}

	get price(): number {
		if (!this.order) {
			return 0;
		}

		return OrderUtils.orderTotalPrice(this.order, true, true, true);
		// return OrderUtils.articleGroupsTotalPrice(
		// 	this.order.orderedArticles,
		// 	this.order.type,
		// 	this.order.preorder?.type,
		// 	this.order.terminalorder?.type
		// );
	}

	get active(): boolean {
		return this.price > 0;
	}

	goToOrder() {
		if (this.active) {
			OrderPage.navigate(this.router);
		}
	}
	goToTable() {
		if (!this.active) {
			this.router.navigateByUrl('multiple-orders');
		}
	}
}
